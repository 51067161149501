import { z } from 'zod'
import { ZCryptId } from '~/common/schema/util'

export const ZStatsPage = z.object({
  items: z
    .object({
      createdAt: z.date(),
      cryptId: ZCryptId,
      name: z.string(),
      roles: z.number(),
      relations: z.number(),
      docs: z.number(),
      processingDocs: z.number(),
      totalFunding: z.number(),
    })
    .array(),
  total: z.number(),
})

export interface ZStatsPage extends z.infer<typeof ZStatsPage> {}

export const ZStatsTotal = z.object({
  users: z.number(),
  corps: z.number(),
  roles: z.number(),
  relations: z.number(),
  docs: z.number(),
  processingDocs: z.number(),
})

export interface ZStatsTotal extends z.infer<typeof ZStatsTotal> {}
